import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Navbar2 from "./Navbar2";

import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div>
      {/* <Navbar2 /> */}
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
