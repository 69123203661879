import React from "react";

const ProductDetails = () => {
  return (
    <div>
      <div>ProductDetails</div>
    </div>
  );
};

export default ProductDetails;
