import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput } from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/auth/userAction';
import { useNavigate } from 'react-router-dom';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const token = localStorage.getItem("user");

  // useEffect(() => {
  //   if (token) {
  //     navigate('/admin/dashboard');
  //   }
  // }, [token, navigate]);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { email, password } = values;

    try {
      setLoading(true);
      setError("");

      await dispatch(login(email, password));
      // navigate('/dashboard');
window.location.reload();
      // const storedToken = localStorage.getItem('token');
      // console.log(storedToken);
      // if (storedToken) {
        
      // }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Invalid email or password. Please try again.");
      resetForm();
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>
      <MDBRow>
        <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>
          <h1 className="my-5 display-3 fw-bold ls-tight px-3" style={{ color: '#999999' }}>
            The best offer <br />
            <span style={{ color: 'hsl(218, 81%, 75%)' }}>for your business</span>
          </h1>
          <p className='px-3'  style={{ color: '#999999' }}>
            Vision Pro is a premier sports apparel company with over 15+ years of experience, prominent for our commitment to quality and modernization. We serve clients internationally, offering a wide series of high-performance sportswear tailored to athletes' needs. At Vision Pro, we are enthusiastic about providing exceptional products and supreme customer service, warranting that every athlete can perform at their best.
          </p>
        </MDBCol>
        <MDBCol md='6' className='position-relative'>
          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
              <h1>Login</h1>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <Field name='email' type='email' as={MDBInput} label='Email' wrapperClass='mb-4' />
                    <ErrorMessage name='email' component='div' className='text-danger' />
                    <Field name='password' type='password' as={MDBInput} label='Password' wrapperClass='mb-4' />
                    <ErrorMessage name='password' component='div' className='text-danger' />
                    <MDBBtn type='submit' className='w-100 mb-4' size='md' disabled={isSubmitting}>
                      {isSubmitting ? 'Logging in...' : 'Login'}
                    </MDBBtn>
                  </Form>
                )}
              </Formik>
              {error && <div className='text-danger'>{error}</div>}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
