import React, { useState, useEffect } from "react";
import AdminMenu from "./AdminMenu";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../redux/category/categoryActions";
import { createProduct } from "../../redux/Products/productAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateProducts = ({ navigation }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    stock: "",
    category: "",
    image: null,
  });

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a temporary URL for the selected image
      const imageUrl = URL.createObjectURL(file);

      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
        imageUrl: imageUrl,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = userData;

    if (!formData.category) {
      toast.error("Please select a category");
      return;
    }

    if (
      !formData.name ||
      !formData.description ||
      !formData.price ||
      !formData.stock ||
      !formData.image
    ) {
      toast.error("Please fill in all fields, including an image");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("file", formData.image);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("price", formData.price);
      formDataToSend.append("stock", formData.stock);
      formDataToSend.append("category", formData.category);

      await dispatch(createProduct(formDataToSend, token));
      setFormData({
        name: "",
        description: "",
        price: "",
        stock: "",
        category: "",
        image: null,
        imageUrl: null,
      });
      toast.success("Product created successfully");
      // navigation.navigate("adminPanel");
    } catch (error) {
      console.error("Error handling upload:", error);
      toast.error("Error creating product: " + error.message);
    }
  };

  return (
    <div className="container-fluid m-3 p-3">
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            <label className="form-label">Product Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Product Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              name="description"
              placeholder="Product Details"
              rows="2"
              value={formData.description}
              onChange={handleInputChange}
            />
            <label className="form-label">Price</label>
            <input
              type="number"
              className="form-control"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
            />
            <label className="form-label">Category</label>
            <select
              className="form-control"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.category}
                </option>
              ))}
            </select>
            <label className="form-label">Size</label>
            <input
              type="number"
              className="form-control"
              name="stock"
              placeholder="Size"
              value={formData.stock}
              onChange={handleInputChange}
            />
            <label className="form-label">Image</label>
            <input
              type="file"
              className="form-control"
              name="image"
              onChange={handleImageChange}
            />
            {formData.imageUrl && (
              <img
                src={formData.imageUrl}
                alt="Selected Image"
                style={{ maxWidth: "80%", height: "80%" }}
              />
            )}

            <button
              type="submit"
              className="btn btn-primary mt-3"
              style={{ backgroundColor: "black", width: "100%", color: "white" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateProducts;
