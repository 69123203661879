import React from "react";
import { NavLink } from "react-router-dom";

const AdminMenu = () => {
  const handleLogout = () => {
    
    // localStorage.removeItem("user");
  // window.location.reload();
  // <NavLink to="/"> </NavLink>
  };
  return (
    <>
      <div className="text-center">
        <div className="list-group">
          <h4>Admin Panel</h4>
          <NavLink
            to="/create-category"
            className="list-group-item list-group-item-action"
          >
            Create Category
          </NavLink>
          <NavLink
            to="/create-product"
            className="list-group-item list-group-item-action"
          >
            Create Product
          </NavLink>
          <NavLink
            to="/edit-product"
            className="list-group-item list-group-item-action"
          >
            Edit Products
          </NavLink>
          <NavLink to="/" className="list-group-item list-group-item-action" onClick={handleLogout}>
      Logout
    </NavLink>
          {/* <NavLink to='/dashboard/admin/orders' className="list-group-item list-group-item-action">Orders</NavLink> */}
          {/* <NavLink to='/dashboard/admin/users' className="list-group-item list-group-item-action">Users</NavLink>   */}
        </div>
      </div>
    </>
  );
};

export default AdminMenu;
