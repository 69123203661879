import React from "react";
import { Outlet } from "react-router-dom";
import imac from "../../Assets/cer/imac.jpg";
import iso from "../../Assets/cer/iso.png";
import psga from "../../Assets/cer/psga.jpg";
import secp from "../../Assets/cer/secp.png";
import sedex from "../../Assets/cer/sedex.png";
import wyoming from "../../Assets/cer/wyoming.png";
import bsci from "../../Assets/cer/BSCI.png";

import service from "../../Assets/Services.mp4";

const About = () => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative", height: "40%" }}>
        {/* <video
          autoPlay
          loop
          muted
          className="video"
          style={{ width: "50%", height: "50%" }}
        >
          <source src={Video} type="video/mp4" />
        </video> */}
        <div
          style={{
            textAlign: "center",
            color: "black",
            fontSize: "40px",
            fontWeight: "bold",
            paddingTop:'5%'
          }}
        >
          About Us
        </div>
      </div>
      <div
        style={{
          margin: ' auto', // Center the div horizontally with auto margins
          textAlign: 'center', // Center-align the text
          fontWeight: 'bold',
          lineHeight: '1.6',
          width: '90%', // Set the width to 80%
          display: 'flex', // Use flexbox for centering
          justifyContent: 'center',
          // backgroundColor: "red",
          fontSize: "18px",
        }}
      >
        Welcome to Vision Pro (Pvt) Ltd, your premier destination for
        top-quality football equipment and specialist goalkeeper gear. At Vision
        Pro, we are dedicated to elevating the game by providing athletes with
        the tools they need to perform at their best.
      </div>
      <br></br>
      <div
        style={{
          textAlign: "center",
          color: "black",
          fontSize: "40px",
          fontWeight: "bold",
          // backgroundColor: "red",
        }}
      >
      
        Our Mission
      </div>
      <div
        style={{
          margin: ' auto', // Center the div horizontally with auto margins
          textAlign: 'center', // Center-align the text
          // fontWeight: 'bold',
          lineHeight: '1.6',
          width: '90%', // Set the width to 80%
          display: 'flex', // Use flexbox for centering
          justifyContent: 'center',
          // backgroundColor: "red",
          fontSize: "18px",
        }}
      >
      
        Our mission is to empower goalkeepers and football players of all levels
        with innovative, durable, and high-performance products. We understand
        the unique demands of the sport and are committed to meeting those needs
        with excellence and precision.
      </div>
      <div
        style={{
          textAlign: "center",
          color: "black",
          fontSize: "40px",
          fontWeight: "bold",
          // backgroundColor: "red",
        }}
      >
      
      Who We Are
      </div>
      <div
        style={{
          margin: ' auto', // Center the div horizontally with auto margins
          textAlign: 'center', // Center-align the text
          // fontWeight: 'bold',
          lineHeight: '1.6',
          width: '96%', // Set the width to 80%
          display: 'flex', // Use flexbox for centering
          justifyContent: 'center',
          // backgroundColor: "red",
          fontSize: "18px",
        }}
      >
      
      Vision Pro (Pvt) Ltd is a passionate team of sports enthusiasts, engineers, and designers who share a common goal: to revolutionize football equipment. With years of experience and a deep love for the game, we bring you products that are meticulously crafted and rigorously tested to ensure they meet the highest standards of quality and performance.
      </div>
      <div style={{ textAlign: 'center', color: 'black', fontSize: '40px', fontWeight: 'bold' }}>
  What We Offer
</div>
<div style={{ margin: 'auto', lineHeight: '1.6', width: '85%', display: 'flex', flexDirection: 'column', fontSize: '18px' }}>
  <div style={{ fontWeight: 'bold', width: '96%', fontSize: '28px' }}>
    Our product range includes:
  </div>
  <ul style={{ padding: 0, textAlign: 'left' }}>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Goalkeeper Gloves:</div>
      <div>Designed for maximum grip, comfort, and protection, our gloves help goalkeepers perform confidently and effectively.</div>
    </li>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Footballs:</div>
      <div>Engineered for superior control, durability, and flight stability, our footballs are perfect for training and competitive play.</div>
    </li>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Hosiery Items:</div>
      <div>From compression socks to training gear, our hosiery items provide comfort and support for peak performance.</div>
    </li>
  </ul>
</div>

<div style={{ textAlign: 'center', color: 'black', fontSize: '40px', fontWeight: 'bold' }}>
  Why Choose Vision Pro
</div>
<div style={{ margin: 'auto', lineHeight: '1.6', width: '85%', display: 'flex', flexDirection: 'column', fontSize: '18px' }}>
  <div style={{ fontWeight: 'bold', width: '98%', fontSize: '28px' }}>
    {/* Our product range includes: */}
  </div>
  <ul style={{ padding: 0, textAlign: 'left' }}>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Quality and Innovation:</div>
      <div>We constantly innovate to bring you the latest in sports technology and design.</div>
    </li>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Customer Satisfaction:</div>
      <div>Your success is our priority. We are committed to providing exceptional customer service and support.</div>
    </li>
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontWeight: 'bold' }}>Passion for Excellence:</div>
      <div>Our dedication to the sport drives us to create products that help athletes achieve their best.</div>
    </li>
  </ul>
</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "2%",
          marginBottom: "5%",
          // backgroundColor: "lightblue",
        }}
      >
        <video
          autoPlay
          loop
          muted
          className="video"
          style={{ width: "80%", maxWidth: "800px", border: "2px solid red" }}
        >
          <source src={service} type="video/mp4" />
        </video>
      </div>
      <div
        style={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          lineHeight: "1.6",

          paddingTop: "4%",
          fontSize: "30px",
        }}
      >
        Certifications
      </div>
      <div
        className="container-fluid"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={imac} alt="imac" style={{ width: "50%" }} />
        </div>
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={iso} alt="iso" style={{ width: "50%" }} />
        </div>
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={psga} alt="psga" style={{ width: "50%" }} />
        </div>
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={secp} alt="secp" style={{ width: "50%" }} />
        </div>
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={sedex} alt="sedex" style={{ width: "50%" }} />
        </div>
        <div
          className="col-md-4 col-sm-6 col-12"
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img src={wyoming} alt="wyoming" style={{ width: "50%" }} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          width: "80%",
          height: "auto",
          paddingBottom: "35px",
        }}
      >
        <img src={bsci} style={{ width: "100%" }} />
      </div>{" "}
      <Outlet />
    </div>
  );
};

export default About;
