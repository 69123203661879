import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <MDBFooter
        bgColor="black"
        className="text-center text-lg-start text-muted "
      >
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block text-white">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
          <MDBBtn
            floating
            style={{ backgroundColor: '#3b5998' }}
            href='https://www.facebook.com/share/oTXdLpS5gmifAAz4/?mibextid=WC7FNe'
            role='button'
            className="m-1"
          >
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>

          <MDBBtn
            floating
            style={{ backgroundColor: 'green' }}
            href='https://wa.me/message/WUXHS6D4O5ERF1'
            role='button'
            className="m-1"
          >
            <MDBIcon fab icon='whatsapp' />
          </MDBBtn>

          <MDBBtn
            floating
            style={{ backgroundColor: '#dd4b39' }}
            href='https://youtube.com/@visionprosports?si=Z8F_QJ598ydVTVQq'
            role='button'
            className="m-1"
          >
            <MDBIcon fab icon='youtube' />
          </MDBBtn>
          <MDBBtn
            floating
            style={{ backgroundColor: '#ac2bac' }}
            href='https://www.instagram.com/visionprosports?igsh=ZHJ0OWd1eW1hNzll'
            role='button'
            className="m-1"
          >
            <MDBIcon fab icon='instagram' />
          </MDBBtn>

         </div>

          
          {/* <div className="text-white">
            <a
              href="https://www.facebook.com/share/oTXdLpS5gmifAAz4/?mibextid=WC7FNe"
              target="_blank"
              rel="noopener noreferrer"
              className="me-4 text-reset"
            
            >
              <MDBIcon fab icon="fab fa-facebook-f" style={{color: "#3b5998" }} />
            </a>
            <a
              href="https://youtube.com/@visionprosports?si=Z8F_QJ598ydVTVQq"
              target="_blank"
              rel="noopener noreferrer"
              className="me-4 text-reset"
            >
              <MDBIcon fab icon="youtube" style={{color: "red" }}/>
            </a>
       
            <a
              href="https://www.instagram.com/visionprosports?igsh=ZHJ0OWd1eW1hNzll"
              target="_blank"
              rel="noopener noreferrer"
              className="me-4 text-reset"
            >
              <MDBIcon fab icon="instagram" />
            </a> */}

          {/* </div> */}
        </section>

        <section className="text-white">
          <MDBContainer className="text-center text-md-start mt-5 ">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <MDBIcon icon="gem" className="me-3" />
                  VISION PRO PVT LTD.
                </h6>
                <p style={{ textAlign: "justify" }}>
                  Vision Pro is a premier sports apparel company with over 15+
                  years of experience, prominent for our commitment to quality
                  and modernization. We serve clients internationally.
                </p>
              </MDBCol>

              {/* <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Angular
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    React
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Vue
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Laravel
                  </a>
                </p>
              </MDBCol> */}

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
                {/* <Link to="/quality" className="nav-link">
                  Quality
                </Link> */}
                <Link to="/contact" className="nav-link">
                  Get in Touch
                </Link>
                <Link to="/products" className="nav-link">
                  Products
                </Link>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon icon="location-dot" className="me-2" />
                  9A Small Industrial Area Sialkot {"  "}Pakistan
                </p>
                
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  info@visionproxports.com  
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> +92 (328) 933-6625
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> +1 (929) 676‑8256
                </p>
                
                {/* <p>
                  <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
                </p> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4 text-white"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 Copyright:
          <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
            Vision Pro
          </a>{" "}
          <br></br>
          {/* <a href="/" className="me-5 text-white">
            <MDBIcon fab icon="apple-pay" size="4x" />
          </a>
          <a href="/" className="me-5 text-white">
            <MDBIcon fab icon="paypal" size="3x" />
          </a> */}
          
        </div>
      </MDBFooter>
    </div>
  );
}

export default Footer;
