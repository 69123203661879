import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import About1 from "../../Assets/About1.jpg";
import About2 from "../../Assets/About2.JPG";
import { Outlet } from "react-router-dom";

import video from "../../Assets/viedostart.mp4";
import Model from "./Model";

const Start = () => {
  const [showModal, setShowModal] = useState(true); // Set showModal to true initially

  useEffect(() => {
    
    const hasShownModal = localStorage.getItem("hasShownModal");
    if (hasShownModal) {
      setShowModal(false); 
    } else {
      localStorage.setItem("hasShownModal", "true"); 
    }
  }, []); 
  const handleCloseModal = () => {
    setShowModal(false); // Function to close the modal
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_h3y0okv", "template_ozc2wea", e.target, {
        publicKey: "XgtP5s_gJBcHByvGS",
      })
      .then(
        () => {
          toast.success("Email sent successfully!");
          // Additional logic after successful form submission
          handleCloseModal(); // Close the modal
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      {/* {showModal && <Model handleCloseModal={handleCloseModal} handleSubmit={handleSubmit} />}  */}
     

      {/* <img src={Image} style={{ width: "100%" }} /> */}

      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "56.25%",
          overflow: "hidden",
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>

      <div
        className="container-fluid p-2 w-75"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5%",
          justifyContent: "space-evenly",
          fontFamily: "bold",
          flexDirection: "row",
          paddingTop: "7%",
          fontWeight: "bold",
        }}
      >
        <h1 style={{ fontWeight: "bold" }}>WELCOME TO VISION PRO PVT LTD</h1>
      </div>
      {/* <SubNav /> */}
      <div
        className="container-fluid p-2 w-75"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
          paddingTop: "7%",
          textAlign: "justify",
        }}
      >
        <p>
          Welcome to Vision Pro, your trusted partner in premium sports apparel.
          With over 15 years of experience, we have established ourselves as a
          leading manufacturer and exporter of high-quality sportswear, situated
          in Pakistan and the US, serving clients all over the world. Our
          commitment to excellence has earned us the trust of over 100+
          satisfied customers across the globe. Our state-of-the-art facilities,
          combined with our team of skilled professionals, enable us to deliver
          exceptional products that meet the highest standards of quality,
          comfort, and performance. From custom team uniforms to
          high-performance activewear, we cater to a diverse range of clients,
          from professional sports teams to fitness enthusiasts. At Vision Pro,
          we prioritize innovation, sustainability, and customer satisfaction,
          ensuring that our products not only meet but exceed our clients'
          expectations. Join the Vision Pro family today and experience the
          difference for yourself!
        </p>
      </div>

      <div
        className="container-fluid p-4"
        style={{
          backgroundColor: "black",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          className="col-md-6 col-12"
          style={{
            order: 1,
          }}
        >
          <img src={About1} style={{ width: "100%" }} />
        </div>
        <div
          className="col-md-4 col-12"
          style={{
            order: 2,
            color: "white",
            textAlign: "justify",
          }}
        >
          <p>
            <strong>Our Specialties</strong>
            <br />
            <br />
            At Vision Pro, we specialize in creating premium sports apparel with
            a focus on football, goalkeeping gear, basketball, futsal, and
            American rugby, among other sports. Our products are designed for
            athletes of all levels, from amateur to professional, ensuring
            performance, durability, and style. We use cutting-edge technology
            and high-quality materials to deliver sportswear that meets the
            highest industry standards.
          </p>
        </div>
      </div>
      <div
        className="container-fluid p-4"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          className="col-md-4 col-12"
          style={{
            order: 1,
            textAlign: "justify",
          }}
        >
          <p>
            <strong>A Modern Manufacturing Company</strong>
            <br />
            <br />
            Vision Pro (Pvt) Ltd is a modern manufacturing company that stands at the forefront of technological innovation and efficiency, seamlessly integrating advanced automation, cutting-edge robotics, and data-driven processes into its operations. Specializing in top-quality football equipment and specialist goalkeeper gear, Vision Pro leverages state-of-the-art machinery and smart manufacturing techniques to produce high-quality products with remarkable precision and consistency. Committed to sustainability, Vision Pro incorporates eco-friendly practices and materials, reducing its environmental footprint while enhancing productivity. The company’s agile approach allows for rapid adaptation to market changes and customer needs, ensuring a competitive edge in a fast-paced global economy. With a focus on continuous improvement and excellence, Vision Pro exemplifies the future of production, blending technology and human expertise to drive progress and innovation in the sports equipment industry.
          </p>
        </div>
        <div
          className="col-md-6 col-12"
          style={{
            order: 2,
          }}
        >
          <img src={About2} style={{ width: "100%" }} />
        </div>
      </div>

      {/* <SubNav /> */}

      <Outlet />
    </>
  );
};

export default Start;
