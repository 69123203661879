import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminMenu from "./AdminMenu";
import { deleteProduct, fetchProducts } from "../../redux/Products/productAction";
import "./editProduct.css";

const EditProduct = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleDelete = async (productId) => {
    const { token } = userData;
    try {
      await dispatch(deleteProduct(productId, token));
      toast.success("Product deleted successfully");
      dispatch(fetchProducts()); // Refresh the product list after deletion
    } catch (error) {
      toast.error("Failed to delete product");
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="container-fluid m-3 p-3">
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="col-md-9">
          <h2 className="mb-4">Products</h2>
          <div className="row">
            {products.map((product) => (
              <div key={product._id} className="col-md-4 mb-3">
                <div className="card">
                  {product.images && product.images[0] && (
                    <img
                      src={product.images[0].url}
                      style={{
                        width: "100%",
                        height: "278px",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}
                      className="card-img-top"
                      alt={product.name}
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">
                      <strong>Name:</strong> {product.name}
                    </h5>
                    <p className="card-text">
                      <strong>Description:</strong> {product.description}
                    </p>
                    <p className="card-text">
                      <strong>Category:</strong> {product.category?.category}
                    </p>
                    <p className="card-text">
                      <strong>Price:</strong> ${product.price}
                    </p>
                    <p className="card-text">
                      <strong>Stock:</strong> {product.stock}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Uncomment this block when you add handleEdit function */}
                      {/* <button
                        className="btn btn-dark"
                        onClick={() => handleEdit(product._id)}
                      >
                        Edit
                      </button> */}
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(product._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditProduct;
