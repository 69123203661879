import React, { useEffect, useState } from "react";
import AdminMenu from "./AdminMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "../../redux/category/categoryActions";
import "./CreateCategory.css";
import { Modal, Button, Input } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateCategory = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(false); // New state variable
  const categories = useSelector((state) => state.category.categories);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch, updateStatus]); // Include updateStatus in the dependencies array

  const handleDelete = (categoryId) => {
    const { token } = userData;
    dispatch(deleteCategory(categoryId, token))
      .then(() => {
        toast.success("Category deleted successfully");
        setUpdateStatus(!updateStatus); // Trigger update after deletion
      })
      .catch((error) => toast.error("Error deleting category: " + error.message));
  };

  const handleEdit = (categoryId) => {
    const category = categories.find((cat) => cat._id === categoryId);
    if (category) {
      setSelectedCategory(category);
      setEditValue(category.category);
    }
    setShowModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { token } = userData;
    dispatch(createCategory(value, token))
      .then(() => {
        toast.success("Category created successfully");
        setValue(""); // Clear input after submission
        setUpdateStatus(!updateStatus); // Trigger update after creation
      })
      .catch((error) => toast.error("Error creating category: " + error.message));
  };

  const handleUpdate = () => {
    const { token } = userData;
    if (selectedCategory && selectedCategory._id) {
      dispatch(updateCategory(selectedCategory._id, editValue, token))
        .then(() => {
          setShowModal(false);
          setUpdateStatus(!updateStatus); // Trigger update after edit
          toast.success("Category updated successfully");
        })
        .catch((error) => toast.error("Error updating category: " + error.message));
    } else {
      toast.error("Invalid category ID");
    }
  };

  return (
    <div className="container-fluid m-3 p-3">
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="col-md-9">
          <div className="card w-75 p-3">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter New Category"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary" style={{backgroundColor:'black' , width:'100%',color:'white'}}>
                Submit
              </button>
            </form>
          </div>
          <div className="card w-75 p-3">
            <h4>Categories:</h4>
            <ul className="list-group">
              {categories.map((category) => (
                <li key={category._id} className="list-group-item d-flex justify-content-between align-items-center">
                  <span className="category-name">{category.category}</span>
                  <div className="category-buttons">
                    <button
                      type="button"
                      className="btn btn-edit btn-sm"
                      style={{backgroundColor:'black' , width:'100%',color:'white'}}
                      onClick={() => handleEdit(category._id)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-delete btn-sm"
                      style={{backgroundColor:'red',width:'100%',color:'white'}}
                      onClick={() => handleDelete(category._id)}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        title="Edit Category"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdate}>
            Update
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter updated category"
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
        />
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CreateCategory;
