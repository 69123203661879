import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  userData: {},
  token: null,
  message: "",
  isAuth: false,
  error: null,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase("loginRequest", (state, action) => {
    state.loading = true;
  });
  builder.addCase("logoutRequest", (state, action) => {
    state.token = action.payload;
  });
  builder.addCase("logingSucess", (state, action) => {
    state.loading = false;
    state.userData = action.payload.user;
    state.token = action.payload.token;
    state.message = action.payload.message;
    state.isAuth = true;
  });
  builder.addCase("loginFail", (state, action) => {
    state.isAuth = false;
    state.error = action.payload;
  });
  // ERROR MESSAGE CASE
  builder.addCase("clearError", (state) => {
    state.error = null;
  });
  builder.addCase("clearMessage", (state) => {
    state.message = null;
  });
});
