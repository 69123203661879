import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./Components/Layout/Layout";
import PageNotFound from "./Components/PageNotFound";
import Login2 from "./Components/Admin/Login2";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import CreateCategory from "./Components/Admin/CreateCategory";
import CreateProducts from "./Components/Admin/CreateProducts";
import EditProduct from "./Components/Admin/EditProduct";
import Start from "./Components/pages/Start";
import About from "./Components/pages/About";
import GetinTouch from "./Components/pages/GetinTouch";
import Quality from "./Components/pages/Quality";
import ProductDetails from "./Components/pages/ProductDetails";
import Requestaquote from "./Components/pages/Requestaquote";
import Search from "./Components/pages/Search";
import CatrgoryPrducts from "./Components/pages/CatrgoryPrducts";
import Products from "./Components/pages/Products";
import store from "./redux/store";

const isAuthenticated = () => {
  const token = localStorage.getItem("user");
  return token ? token : null;
};

function App() {
  const token = isAuthenticated();

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Start />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<GetinTouch />} />
            <Route path="/quality" element={<Quality />} />
            <Route path="/productdetail/:id" element={<ProductDetails />} />
            <Route path="/request-a-quote" element={<Requestaquote />} />
            <Route path="/search" element={<Search />} />
            <Route path="/products" element={<Products />} />
            <Route
              path="/CatgoryProduct/:categoryId"
              element={<CatrgoryPrducts />}
            />
          </Route>

          {/* Login Route */}
          <Route
            path="/login"
            element={token ? <Navigate to="/dashboard" /> : <Login2 />}
          />

          {/* Protected Admin Routes */}
          {token ? (
            <Route >
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/create-category" element={<CreateCategory />} />
              <Route path="/create-product" element={<CreateProducts />} />
              <Route path="/edit-product" element={<EditProduct />} />
            </Route>
          ) : (
            <Route path="/admin/*" element={<Navigate to="/admin/login" />} />
          )}

          {/* Fallback Route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
