import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategory } from "../../redux/category/categoryActions";

const SubNav = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleCategoryClick = () => {
    // Close the dropdown programmatically
    const dropdownElement = document.querySelector(".dropdown-toggle");
    if (dropdownElement) {
      const dropdownInstance = new window.bootstrap.Dropdown(dropdownElement);
      dropdownInstance.hide();
      
      // Check if the user is on a mobile device
      if (window.innerWidth <= 768) {
        // window.location.reload();
      }
    }
  };

  return (
    <div className="justify-content-center">
      <div className="dropdown text-center">
        <div
          className="dropdown-toggle"
          type="text"
          data-bs-toggle="dropdown"
        >
          Products
        </div>
        <ul className="dropdown-menu">
          <li style={{ alignContent: 'center', justifyContent: 'center' }}>
            <Link className="nav-link" to="/products" onClick={handleCategoryClick}>
              All Products
            </Link>
          </li>
          {categories.map((category) => (
            <li key={category._id} style={{ alignContent: 'center', justifyContent: 'center' }}>
              <Link
                to={`/CatgoryProduct/${category._id}`}
                className="dropdown-item"
                onClick={handleCategoryClick}
              >
                {category.category}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubNav;
