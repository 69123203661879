import React from 'react'
import Productcard from './Productcard'
import SubNav from './SubNav'

const Products = () => {
  return (
    <>
    {/* <SubNav/> */}
    <div className="d-flex justify-content-center" style={{ fontWeight: "bold",fontSize:'25px',alignContent:'center',paddingTop:'20px' }}>
        <div>Products</div>
      </div>
    <Productcard /></>
  )
}

export default Products