import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../redux/store";

const CategoryProducts = () => {
  const { categoryId } = useParams(); // Get the categoryId from the URL
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/category/${categoryId}`);
        setCategoryName(response.categoryName);
        setProducts(response.data.products);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId]); 

  return (
    <div>
        <div className="d-flex justify-content-center">
        <h1 style={{ fontWeight: "bold", paddingTop: '10px' }}>{categoryName ? categoryName : "Category Products"}</h1>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "1%",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {products.length === 0 ? (
            <div>No products available in this category.</div>
          ) : (
            products.map((product) => (
              <div
                key={product._id}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  margin: "10px",
                  padding: "10px",
                  transition: "transform 0.3s ease-in-out",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  ":hover": { transform: "scale(1.05)" },
                }}
              >
                <img
                  src={product.images[0].url}
                  alt={product.name}
                  style={{
                    width: "100%",
                    height: "278px",
                    objectFit: "cover",
                    marginBottom: "10px",
                  }}
                />
                <h3 style={{ marginTop: 10 }}>{product.name}</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ margin: 0 }}> {product.description}</p>
                  <p style={{ margin: 0 }}>Size: {product.stock}</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryProducts;
