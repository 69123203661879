import React, { useState } from "react";
import { Link } from "react-router-dom";
import Search from "../pages/Search";
import { useDispatch } from "react-redux";
import {
  fetchProducts,
  getnameProduct,
} from "../../redux/Products/productAction";
import logo from "../../Assets/logo.jpg";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import user from "../../Assets/user.png";
import SubNav from "../pages/SubNav";

const Navbar = () => {
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (searchText.trim() === "") {
      dispatch(fetchProducts());
    }
  };

  const handleSearch = () => {
    if (searchText.trim() !== "") {
      console.log("Sending search text to Search component:", searchText);
      dispatch(getnameProduct(searchText))
        .then((data) => {
          console.log("Fetched data:", data);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else {
      // If search text is empty, fetch all products
      dispatch(fetchProducts())
        .then((data) => {
          console.log("Fetched all products:", data);
        })
        .catch((error) => {
          console.error("Error fetching all products:", error);
        });
    }
  };

  return (
    <div>
      {/* <nav
        className="navbar navbar-expand-lg sticky-top"
        style={{ height: "58px", }}
      >
        <div
          className="container-fluid w-75"
          style={{ marginRight: "10%", paddingLeft: "6%", flex: 1 }}
        >
          <button
            style={{ backgroundColor: "white" }}
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              style={{ backgroundColor: "white" }}
              class="navbar-toggler-icon"
            ></span>
          </button> */}
      <nav
        class="navbar navbar-expand-sm bg-light "
        style={{ backgroundColor: "white", height: "59px" }}
      >
        <div
          class="container-fluid "
          style={{ backgroundColor: "white", zIndex: 10, width: "95.2%" }}
        >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarTogglerDemo01">
      <Link className="navbar-brand" to="/">
        Vision Pro
      </Link>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products">
            <SubNav />
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">
            About Us
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">
            Get in Touch
          </Link>
        </li>
      </ul>
    </div>
          <div className="d-flex align-items-center ms-auto">
            <form className="input-group  d-sm-flex">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchText}
                onChange={handleChange}
              />
              <Link
                to={`/search?q=${encodeURIComponent(searchText)}`}
                className="input-group-text"
              >
                <i className="fas fa-search"></i>
              </Link>
            </form>
            <Link to="/login" className="ms-3">
  <i className="far fa-user"></i>
</Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
