import axios from "axios";
import { server } from "../store";

export const login = (email, password) => async (dispatch) => {
  console.log(email, password);

  try {
    dispatch({
      type: "loginRequest",
    });

    // hitting node login api request
    const response = await axios.post(
      `${server}/user/login`,
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log(response.data, "response");

    if (response?.data?.token) {
      // Store user data in localStorage upon successful login
      localStorage.setItem("user", JSON.stringify(response.data));

      dispatch({
        type: "loginSuccess",
        payload: response.data,
      });
    } else {
      alert("Wrong Credentials");
      dispatch({
        type: "loginFail",
        payload: "Wrong Credentials",
      });
    }
  } catch (error) {
    alert("Error during login");
    dispatch({
      type: "loginFail",
      payload: error.response?.data?.message || "Error during login",
    });
  }
};
