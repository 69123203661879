import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchProducts,
  getnameProduct,
} from "../../redux/Products/productAction";
import { Link } from "react-router-dom";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchText = searchParams.get("q");

  console.log("Search searchText:", searchText);

  useEffect(() => {
    if (searchText) {
      dispatch(getnameProduct(searchText));
    } else {
      dispatch(fetchProducts());
    }
  }, [dispatch, searchText]);

  const { products, message } = useSelector((state) => state.product);
  console.log("products:", products);

  return (
    <div>
      <h2 style={{ textAlign: "center", paddingTop: "10px" }}>
        Only {products.length} Available in the Search
      </h2>
      <div className="search-results">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",

            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {products.map((product) => (
            <div
              key={product._id}
              style={{
                width: "100%",
                maxWidth: "300px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                margin: "10px",
                padding: "10px",

                transition: "transform 0.3s ease-in-out",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                ":hover": { transform: "scale(1.05)" },
              }}
            >
              <img
                src={product.images[0].url}
                alt={product.name}
                style={{
                  width: "100%",
                  height: "278px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />

              <h3 style={{ marginTop: 10 }}>{product.name}</h3>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: 0 }}> {product.description}</p>
                <p style={{ margin: 0 }}>Size: {product.stock}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
