import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/Products/productAction";

const Productcard = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.product.products);
  console.log(products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "2%",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {products.map((product) => (
            <div
              key={product._id}
              style={{
                width: "100%",
                maxWidth: "300px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                margin: "10px",
                padding: "10px",

                transition: "transform 0.3s ease-in-out",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                ":hover": { transform: "scale(1.05)" },
              }}
            >
              <img
                src={product.images[0].url}
                alt={product.name}
                style={{
                  width: "100%",
                  height: "278px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />

              <h3 style={{ marginTop: 10 }}>{product.name}</h3>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: 0 }}> {product.description}</p>
                <p style={{ margin: 0 }}>Size: {product.stock}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Productcard;
