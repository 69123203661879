import React, { useEffect, useState } from "react";
import Select from "react-select";

// InputField component to handle input fields with labels
const InputField = ({ id, label, value, onChange, required }) => (
  <div className="form-outline mb-3">
    <input
      type="text"
      id={id}
      className="form-control"
      name={id}
      value={value}
      onChange={onChange}
      required={required}
      style={{ borderBottom: "1px solid #ced4da" }}
    />
    <label className="form-label" htmlFor={id}>
      {label} {required && "*"}
    </label>
  </div>
);

const Requestaquote = () => {
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    companyName: "",
    country: null, // Initialize country as null
    city: "",
    description: "",
  });

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const handleChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
  };

  return (
    <div className="container">
      <div style={{ marginBottom: "20px", fontSize: "1.2rem" }}>
        Complete control over products allows us to ensure our customers receive
        the best quality prices and service. We take great pride in everything
        that we do.
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col">
            <InputField
              id="fullName"
              label="Full Name"
              value={formData.fullName}
              onChange={(e) =>
                setFormData({ ...formData, fullName: e.target.value })
              }
              required
            />
          </div>
          <div className="col">
            <InputField
              id="email"
              label="Email Address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <InputField
              id="phone"
              label="Phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              required
            />
          </div>
          <div className="col">
            <InputField
              id="companyName"
              label="Company Name"
              value={formData.companyName}
              onChange={(e) =>
                setFormData({ ...formData, companyName: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <Select
              id="country"
              className="form-control"
              name="country"
              value={formData.country}
              onChange={handleChange}
              options={countries}
              placeholder="Select Country"
            />
          </div>
          <div className="col">
            <InputField
              id="city"
              label="City"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className="form-outline">
              <textarea
                id="description"
                className="form-control"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
              />
              <label className="form-label" htmlFor="description">
                Description
              </label>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Requestaquote;
