import React, { useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";

const Quality = () => {
  const [activeId, setActiveId] = useState(null);

  const handleAccordionClick = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };
  return (
    <>
      <div
        style={{
          margin: "5% 5%",
          textAlign: "justify",
          fontWeight: "bold",
          lineHeight: "1.6",
          paddingTop: "5%",
          fontSize: "18px",
        }}
      >
        Maintaining high quality standards is a key part of Vision Technologies’
        ethos. Over the years, Vision Technologies’ quick ascension to one of
        the largest sports manufacturers from Pakistan can be attributed to the
        increased demand from customers which stemmed from Vision Technologies’
        ability to meet international quality standards. Understanding the
        quality is not a one investment, Vision Technologies continues to
        reinvest in its quality standards to meet and exceed international
        standards. Over the years, modernizations efforts continue to transform
        Vision Technologies into a Lean Manufacturing organization and building
        upon the Toyota Production System to continuously improve our quality
        standards that not only meet but also exceed the requirements of our
        customers.
      </div>
      <div
        style={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          lineHeight: "1.6",
          paddingTop: "4%",
          fontSize: "18px",
        }}
      >
        Certifications
      </div>
      <div
        style={{
          margin: "5% 5%",
          textAlign: "justify",
          fontWeight: "bold",
          lineHeight: "1.6",

          fontSize: "18px",
        }}
      >
        Vision Technologies has a thorough quality control process to ensure
        adherence to quality standards. The process itself is dynamic, not
        static, and evolves overtime to allow for continuous improvement and
        superior products for Vision Technologies’ customers. Moreover, quality
        control measures exist throughout the production process to offer multi
        stage checks which helps to produce a superior end product for
        customers.
      </div>
      <div
        style={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          lineHeight: "1.6",
          paddingTop: "4%",
          fontSize: "18px",
        }}
      >
        Process
      </div>
      <div
        style={{
          margin: "5% 5%",
          textAlign: "justify",
          fontWeight: "bold",
          lineHeight: "1.6",

          fontSize: "18px",
        }}
      >
        Vision Technologies has a thorough quality control process to ensure
        adherence to quality standards. The process itself is dynamic, not
        static, and evolves overtime to allow for continuous improvement and
        superior products for Vision Technologies’ customers. Moreover, quality
        control measures exist throughout the production process to offer multi
        stage checks which helps to produce a superior end product for
        customers.
      </div>
      <MDBContainer className="mt-5" style={{ maxWidth: "1250px" }}>
        <MDBAccordion>
          <MDBAccordionItem
            collapseId={1}
            headerTitle="Material Development"
            style={{
              backgroundColor: activeId === 1 ? "lightgray" : "white",
            }}
            onClick={() => handleAccordionClick(1)}
          >
            <div style={{ textAlign: "center", fontFamily: "bold", flex: 1 }}>
              Casing Material
            </div>
            <br></br>
            When the planning team conceives an idea of a product article, a
            suitable casing material is developed in the given range of the
            product. Some common casing materials include Polyurethane, TPU,
            PVC, among several others. After the appropriate casing material has
            been selected, a series of tests are conducted to ensure quality of
            the casing material.
            <li>
              1. Physical properties: surface appearance, color shades, touch
              feelings, GSM, thickness
            </li>
            <li>
              2. Mechanical properties: Tensile testing to check elongation
              which helps to achieve the appropriate shape of a ball
            </li>
            <li>
              3. Chemical testing: Hydrolysis test with an alkaline solution at
              a pH value between 13 ~ 15. This test ensures the life cycle of
              the product.
            </li>
            <div style={{ textAlign: "center", fontFamily: "bold", flex: 1 }}>
              Backing Material
            </div>
            <br></br>
            When the planning team conceives an idea of a product article, a
            suitable casing material is developed in the given range of the
            product. Some common casing materials include Polyurethane, TPU,
            PVC, among several others. After the appropriate casing material has
            been selected, a series of tests are conducted to ensure quality of
            the casing material.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="Product Development"
            style={{
              backgroundColor: activeId === 2 ? "lightgray" : "white",
            }}
            onClick={() => handleAccordionClick(2)}
          >
            After quality has been approved in the material development process,
            the product development team conducts a series of steps on a
            finished product item.
            <li>
              1. Physical properties: surface appearance, color shades, touch
              feelings, GSM, thickness
            </li>
            <li>
              2. Ink: ink formulation is set for graphics of the ball. As balls
              are played on different types of surfaces, a lot of abrasion
              occurs during play, this abrasion tests are performed through
              special equipment designed to test how the ink withstands
              abrasion.
            </li>
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Question #3"
            className="text-center"
            style={{
              backgroundColor: activeId === 3 ? "lightgray" : "white",
            }}
            onClick={() => handleAccordionClick(3)}
          >
            <strong>This is the third item's accordion body.</strong> It is
            hidden by default, until the collapse plugin adds the appropriate
            classes that we use to style each element. These classes control the
            overall appearance, as well as the showing and hiding via CSS
            transitions. You can modify any of this with custom CSS or
            overriding our default variables. It's also worth noting that just
            about any HTML can go within the <code>.accordion-body</code>,
            though the transition does limit overflow.
          </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>
    </>
  );
};

export default Quality;
